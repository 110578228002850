import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';

import { Layout, SEO } from '../../components';
import AffiliateDisclaimer from '../../components/rehype/Disclaimer';
import Arrow from '../../images/quiz/arrow.inline.svg';
import { useClickIdEnhancedLink } from '../../lib/tracking';

const REWARD_LINK =
  'https://go.nordpass.io/aff_c?offer_id=488&aff_id=45264&source=survey-reward';
const PIXEL_LINK =
  'https://go.nordpass.io/aff_i?offer_id=488&aff_id=45264&source=survey-reward';

const SurveyForParentsRewardPage = () => {
  const { ctaImage } = useStaticQuery(imageQuery);
  const enhancedRewardHref = useClickIdEnhancedLink(REWARD_LINK);
  const enhancedPixelHref = useClickIdEnhancedLink(PIXEL_LINK);

  return (
    <Layout bodyClass="SurveyForParentsRewardPage min-h-screen">
      <SEO title="Your Reward!" url="/survey-for-parents/reward/" noIndex />
      <div className="container mx-auto p-4">
        <svg
          id="a4baa7a1-c1fd-441f-b894-2b913f22f48f"
          className="h-40 mx-auto"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 633.43028 455.75529"
        >
          <path
            d="M428.06643,358.64415c1.34056.834,2.65686,1.68345,3.971,2.56067A336.1591,336.1591,0,0,1,455.4885,470.69776q.30332,6.51411.30779,13.00816a326.40578,326.40578,0,0,1-14.01784,95.83842q-.74505,2.45352-1.52234,4.88732c-.3714,1.16438-.74948,2.33986-1.15967,3.495-.436.59215-.8656,1.17379-1.29492,1.75485-.03041.02551-.05679.06865-.08752.09468-.17308.23464-.34614.46938-.5017.69945-.74261.99672-1.4962,1.98744-2.23855,2.98358l.62532.04257,1.56748.09956-.00644.01053c-.52627-.01084-1.06326-.0284-1.59385-.05642-.19284.00065-.40291.00522-.59972-.01174-.17563-.00328-.36172-.01368-.53735-.017-22.60058-.61235-45.72981-1.74007-66.29619-10.98258-21.52643-9.682-39.78662-30.615-38.85866-54.19212a42.31731,42.31731,0,0,1,1.39721-9.13813c.33312-1.247.7268-2.47226,1.14855-3.69477a44.55589,44.55589,0,0,1,76.44183-13.99088c-6.16917-24.24478-32.65352-36.61662-56.5073-44.13839-23.87126-7.51728-50.72869-15.38054-62.92432-37.2256-6.80905-12.15642-6.973-26.31993-2.53-39.39882.13526-.39076.27734-.79253.43007-1.18779a65.01533,65.01533,0,0,1,31.35147-35.59146c27.27947-13.50633,60.42731-8.74086,88.37846,3.31408A161.15906,161.15906,0,0,1,428.06643,358.64415Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#f2f2f2"
          />
          <path
            d="M349.87856,515.61908a100.31968,100.31968,0,0,1,57.72394,25.14983,102.65649,102.65649,0,0,1,29.18957,45.79377c.31668,1.0075.605,2.01286.87592,3.02273.5417-1.03136,1.068-2.08585,1.58814-3.1304.33471-.66854.67618-1.34813,1.00011-2.02327-.3714,1.16438-.74948,2.33986-1.15967,3.495-.436.59215-.8656,1.17379-1.29492,1.75485-.03041.02551-.05679.06865-.08752.09468-.17308.23464-.34613.46938-.5017.69945-.74261.99672-1.4962,1.98744-2.23855,2.98358l.62533.04257,1.56747.09956-.00643.01053c-.52628-.01084-1.06326-.0284-1.59385-.05642-.19285.00065-.40292.00522-.59973-.01174-.0648-.30561-.14681-.60729-.22857-.90954a96.92964,96.92964,0,0,0-104.0664-73.42043c.33312-1.247.7268-2.47226,1.14854-3.69477A103.69269,103.69269,0,0,1,349.87856,515.61908Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#fff"
          />
          <path
            d="M421.11993,425.94174a110.37359,110.37359,0,0,1,22.60512,22.76091,91.57052,91.57052,0,0,1,8.63566,14.66528c1.14579,2.38755,2.18892,4.84606,3.1279,7.3298q.3033,6.51411.30778,13.00817a94.57078,94.57078,0,0,0-2.91108-9.67c-.59411-1.74019-1.25372-3.446-1.9547-5.13223-7.862-18.88486-22.10614-33.79559-38.90545-45.132-18.00171-12.13008-38.29636-21.23611-58.86378-28.00719a271.66443,271.66443,0,0,0-65.071-12.98687,1.94823,1.94823,0,0,1-1.78973-2.01214,1.67649,1.67649,0,0,1,.43006-1.18779,1.392,1.392,0,0,1,1.19455-.38c2.7321.20438,5.45712.42035,8.182.70923a274.88381,274.88381,0,0,1,66.36854,15.31975C383.13637,402.76527,403.58382,412.54151,421.11993,425.94174Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#fff"
          />
          <circle cx="25.49633" cy="91.79682" r="7.57809" fill="#f2f2f2" />
          <rect
            x="147.04623"
            y="161.75529"
            width="344"
            height="293"
            fill="#fff"
          />
          <path
            d="M775.33109,677.87765h-346v-295h346Zm-344-2h342v-291h-342Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#3f3d56"
          />
          <rect
            x="276.04623"
            y="218.75529"
            width="86"
            height="236"
            fill="#5b7024"
          />
          <rect
            x="276.04623"
            y="218.75529"
            width="86"
            height="236"
            fill="#5b7024"
          />
          <polygon
            points="148.046 183.255 148.046 230.793 491.046 252.755 490.035 194.097 148.046 183.255"
            opacity="0.2"
          />
          <rect
            x="127.04623"
            y="134.75529"
            width="384"
            height="84"
            fill="#fff"
          />
          <path
            d="M795.33109,441.87765h-386v-86h386Zm-384-2h382v-82h-382Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#3f3d56"
          />
          <rect
            x="276.04623"
            y="126.75529"
            width="86"
            height="91"
            fill="#5b7024"
          />
          <path
            d="M593.12319,269.53387c22.70141,33.65162,1.31244,87.77543,1.31244,87.77543s-58.19523-.43625-80.89665-34.08787-1.31243-87.77544-1.31243-87.77544S570.42178,235.88224,593.12319,269.53387Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <path
            d="M603.539,269.53387c-22.70141,33.65162-1.31243,87.77543-1.31243,87.77543s58.19523-.43625,80.89664-34.08787,1.31244-87.77544,1.31244-87.77544S626.2404,235.88224,603.539,269.53387Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <circle cx="259.04623" cy="63.75529" r="9" fill="#3f3d56" />
          <circle cx="313.04623" cy="154.75529" r="9" fill="#3f3d56" />
          <circle cx="313.04623" cy="351.75529" r="9" fill="#3f3d56" />
          <circle cx="283.04623" cy="96.75529" r="6" fill="#3f3d56" />
          <circle cx="373.04623" cy="45.75529" r="6" fill="#3f3d56" />
          <circle cx="334.04623" cy="186.75529" r="6" fill="#3f3d56" />
          <circle cx="298.04623" cy="264.75529" r="6" fill="#3f3d56" />
          <circle cx="329.04623" cy="291.75529" r="6" fill="#3f3d56" />
          <circle cx="322.04623" cy="404.75529" r="6" fill="#3f3d56" />
          <rect
            x="439.21997"
            y="299.32288"
            width="183.03657"
            height="155.90033"
            fill="#fff"
          />
          <path
            d="M906.07348,677.87765H721.97275V520.91315H906.07348Zm-183.03657-1.06417H905.00932V521.97732H723.03691Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#3f3d56"
          />
          <rect
            x="507.85869"
            y="329.65161"
            width="45.75914"
            height="125.5716"
            fill="#5b7024"
          />
          <rect
            x="507.85869"
            y="329.65161"
            width="45.75914"
            height="125.5716"
            fill="#5b7024"
          />
          <polygon
            points="439.752 310.763 439.752 336.057 622.257 347.742 621.719 316.532 439.752 310.763"
            opacity="0.2"
          />
          <rect
            x="428.57831"
            y="284.95664"
            width="204.31989"
            height="44.69498"
            fill="#fff"
          />
          <path
            d="M916.71514,552.30605h-205.384V506.54691H916.71514Zm-204.31989-1.06417H915.651V507.61107H712.39525Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#3f3d56"
          />
          <rect
            x="507.85869"
            y="280.69997"
            width="45.75914"
            height="48.41956"
            fill="#5b7024"
          />
          <path
            d="M809.12375,460.60485c12.079,17.90545.69832,46.70382.69832,46.70382s-30.96469-.23212-43.04373-18.13758-.69832-46.70383-.69832-46.70383S797.04471,442.69939,809.12375,460.60485Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <path
            d="M814.66582,460.60485c-12.079,17.90545-.69833,46.70382-.69833,46.70382s30.9647-.23212,43.04373-18.13758.69833-46.70383.69833-46.70383S826.74485,442.69939,814.66582,460.60485Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <circle cx="498.81327" cy="247.17874" r="4.78875" fill="#3f3d56" />
          <circle cx="527.54576" cy="295.5983" r="4.78875" fill="#3f3d56" />
          <circle cx="527.54576" cy="400.41866" r="4.78875" fill="#3f3d56" />
          <circle cx="511.58327" cy="264.73748" r="3.1925" fill="#3f3d56" />
          <circle cx="559.47074" cy="237.60125" r="3.1925" fill="#3f3d56" />
          <circle cx="538.7195" cy="312.62496" r="3.1925" fill="#3f3d56" />
          <circle cx="519.56451" cy="354.12743" r="3.1925" fill="#3f3d56" />
          <circle cx="536.05909" cy="368.49368" r="3.1925" fill="#3f3d56" />
          <circle cx="532.33451" cy="428.61906" r="3.1925" fill="#3f3d56" />
          <ellipse
            cx="571.13927"
            cy="596.29152"
            rx="6.76007"
            ry="21.53369"
            transform="translate(-495.6615 634.68107) rotate(-64.62574)"
            fill="#2f2e41"
          />
          <ellipse
            cx="665.4546"
            cy="623.75304"
            rx="6.76007"
            ry="21.53369"
            transform="translate(-528.48439 350.57453) rotate(-39.93837)"
            fill="#2f2e41"
          />
          <circle cx="336.3626" cy="394.15279" r="43.06732" fill="#2f2e41" />
          <rect
            x="341.50766"
            y="427.95232"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <rect
            x="315.34018"
            y="427.95232"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <ellipse
            cx="343.68827"
            cy="451.66661"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="317.52079"
            cy="451.12148"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <path
            d="M606.12461,562.13821c3.84558-15.487,20.82056-24.60077,37.91474-20.35617s27.83429,20.24029,23.98871,35.72729-16.60395,15.537-33.69812,11.29236S602.279,577.62524,606.12461,562.13821Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#e6e6e6"
          />
          <circle cx="329.92398" cy="385.0652" r="14.35864" fill="#fff" />
          <circle cx="324.02262" cy="379.91841" r="4.78621" fill="#3f3d56" />
          <path
            d="M622.21623,637.103a9.57244,9.57244,0,1,1-18.83533,3.42883h0l-.00336-.01849c-.94177-5.20215,3.08039-7.043,8.28254-7.98474S621.27452,631.90087,622.21623,637.103Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#fff"
          />
          <ellipse
            cx="704.13927"
            cy="285.67329"
            rx="21.53369"
            ry="6.76007"
            transform="translate(-339.01306 68.32177) rotate(-22.4424)"
            fill="#2f2e41"
          />
          <ellipse
            cx="798.4546"
            cy="305.13481"
            rx="6.76007"
            ry="21.53369"
            transform="translate(-292.91901 361.63329) rotate(-39.93837)"
            fill="#2f2e41"
          />
          <circle cx="469.3626" cy="75.53456" r="43.06732" fill="#2f2e41" />
          <rect
            x="474.50766"
            y="109.33409"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <rect
            x="448.34018"
            y="109.33409"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <ellipse
            cx="476.68827"
            cy="133.04838"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="450.52079"
            cy="132.50324"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <path
            d="M739.12461,243.52c3.84558-15.487,20.82056-24.60077,37.91474-20.35617s27.83429,20.24029,23.98871,35.72729-16.60395,15.537-33.69812,11.29236S735.279,259.007,739.12461,243.52Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <circle cx="462.92398" cy="66.44697" r="14.35864" fill="#fff" />
          <circle cx="457.02262" cy="68.30018" r="4.78621" fill="#3f3d56" />
          <path
            d="M755.21623,318.48473a9.57244,9.57244,0,1,1-18.83533,3.42883h0l-.00336-.01849c-.94177-5.20215,3.08039-7.043,8.28254-7.98474S754.27452,313.28264,755.21623,318.48473Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#fff"
          />
          <circle cx="100.88459" cy="394.15263" r="43.06733" fill="#2f2e41" />
          <rect
            x="81.25897"
            y="427.95232"
            width="13.08374"
            height="23.44171"
            fill="#2f2e41"
          />
          <rect
            x="107.42646"
            y="427.95232"
            width="13.08373"
            height="23.44171"
            fill="#2f2e41"
          />
          <ellipse
            cx="92.16205"
            cy="451.66661"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <ellipse
            cx="118.32959"
            cy="451.12144"
            rx="10.90314"
            ry="4.08868"
            fill="#2f2e41"
          />
          <path
            d="M342.3927,576.22369c-3.47748-15.5738,7.63865-31.31043,24.82865-35.14881s33.94422,5.67511,37.4217,21.2489-7.91492,21.31769-25.10486,25.156S345.87019,591.79748,342.3927,576.22369Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#5b7024"
          />
          <ellipse
            cx="425.27311"
            cy="592.15381"
            rx="23.89244"
            ry="7.50055"
            transform="translate(-577.48668 252.30643) rotate(-45.0221)"
            fill="#2f2e41"
          />
          <ellipse
            cx="332.56775"
            cy="595.15381"
            rx="7.50055"
            ry="23.89244"
            transform="translate(-606.64366 187.1021) rotate(-44.9779)"
            fill="#2f2e41"
          />
          <circle cx="99.07258" cy="388.04941" r="14.71921" fill="#fff" />
          <circle cx="99.07257" cy="388.04942" r="4.90643" fill="#3f3d56" />
          <path
            d="M394.042,637.3943a9.57244,9.57244,0,1,1-18.83533,3.42883h0l-.00335-.01849c-.94178-5.20215,3.08038-7.043,8.28253-7.98474S393.10025,632.19221,394.042,637.3943Z"
            transform="translate(-283.28486 -222.12235)"
            fill="#fff"
          />
        </svg>
        <h1 className="text-secondary text-center mt-4 mb-10">
          Congratulations!
        </h1>
        <div className="text-center">
          Here's your discount for
          <br />
          <a
            href={enhancedRewardHref}
            className="flex items-center justify-center no-underline bg-primary-100 p-8 mt-4"
          >
            <Img
              className="h-24 w-24 mr-8"
              alt="NordPass logo"
              objectFit="contain"
              fluid={ctaImage.childImageSharp.fluid}
            />
            <span className="font-bold text-secondary text-4xl">
              Family
              <br />
              Plan
            </span>
          </a>
        </div>
        <p className="text-gray-500 text-center">
          The only Password Manager your family needs!
        </p>

        <div className="flex items-center mt-4">
          <div className="flex-1 text-center text-2xl">
            {/*<div className="text-error text-sm text-center animate-pulse">*/}
            {/*  Limited time offer!*/}
            {/*</div>*/}
            Your special price:
          </div>
          <div className="flex-1 flex flex-col items-center justify-center">
            <div className="text-gray-500 line-through text-center text-base">
              $299.40
            </div>
            <div className="text-secondary text-center text-3xl font-bold">
              $47.88
            </div>
            <div className="text-error text-sm text-center">Save $251.52</div>
          </div>
        </div>

        <Arrow className="start-arrow mt-8" />
        <a
          href={enhancedRewardHref}
          className="block text-center py-2 text-xl mb-8 btn btn-secondary w-full"
        >
          Redeem your prize
        </a>
        <div className="bg-primary-100 p-4 mb-4">
          * Your price is for NordPass Family Pack. On the NordPass website, go
          to <span className="italic font-bold">Plans > Family Premium</span> to
          activate it!
        </div>
        <AffiliateDisclaimer className="p-4" />
      </div>

      <img
        src={enhancedPixelHref}
        alt="tracking pixel"
        width="0"
        height="0"
        style={{ position: 'absolute', visibility: 'hidden' }}
        border="0"
      />
    </Layout>
  );
};

const imageQuery = graphql`
  query SurveyForParentsRewardQuery {
    ctaImage: file(relativePath: { eq: "cta/NP-logo-c-v.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SurveyForParentsRewardPage;
