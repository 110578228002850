import aa from 'search-insights';
import { StringParam, useQueryParam } from 'use-query-params';

export function shouldTrackLink(href) {
  return (
    href.indexOf('go.nordvpn.net') > -1 ||
    href.indexOf('go.nordpass.io') > -1 ||
    href.indexOf('track.bzfrs.co') > -1 ||
    href.indexOf('track.mspy.click') > -1
  );
}

export const useClickIdEnhancedLink = (href) => {
  const [clickId] = useQueryParam('clickid', StringParam);
  const [utmSource] = useQueryParam('utm_source', StringParam);
  const [utmMedium] = useQueryParam('utm_medium', StringParam);
  const [utmCampaign] = useQueryParam('utm_campaign', StringParam);
  const [banner] = useQueryParam('banner', StringParam);

  if (clickId && shouldTrackLink(href)) {
    return `${href}&aff_sub=${utmSource}&aff_sub2=${utmMedium}&aff_sub3=${utmCampaign}&aff_sub4=${banner}&aff_click_id=${clickId}`;
  }
  return href;
};

aa('init', {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  apiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
});

export const algoliaAnalytics = aa;
